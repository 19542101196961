<flex-row
  class="topbar-warning"
  [class]="
    notification.serviceNotification.priority === 'alert'
      ? 'topbar-warning-high'
      : notification.serviceNotification.priority === 'warning'
        ? 'topbar-warning-medium'
        : 'topbar-warning-low'
  ">
  <div class="service-content__service-icon">
    <app-icon [name]="getServiceIcon(notification.serviceNotification.responsible)" color="var(--jet-black)" style="width: 24px"></app-icon>
  </div>

  @if (notification.serviceNotification.priority === 'info') {
    <div class="service-content__priority">
      <app-icon name="infoInverted" color="var(--jet-black)"></app-icon>
    </div>
  }
  @if (notification.serviceNotification.priority === 'warning') {
    <div class="service-content__priority">
      <app-icon name="warning" color="var(--jet-black)"></app-icon>
    </div>
  }
  @if (notification.serviceNotification.priority === 'alert') {
    <div class="service-content__priority">
      <app-icon name="error" color="var(--jet-black)"></app-icon>
    </div>
  }

  <!-- &lt;!&ndash; Service specific &ndash;&gt;
  <ng-container *ngIf="notification.serviceNotification.responsible === 'emma'">
    <div style="width: 100%; font-size: 14px">
      <span class="bold">{{ emma.nodeNameLookup[asEmma(notification).meter] }} </span>
      <span class="bold">{{ emma.periodLabel(asEmma(notification)) }}</span> consumption of
      <span class="bold"
        >{{ asEmma(notification).parentRule?.threshold + asEmma(notification).threshold | number : '1.2-3'
        }}{{ emma.BASE_UNIT_SYMBOLS[asEmma(notification).parentRule.thresholdUnit.baseUnit.unit] }} ({{
        asEmma(notification).threshold | number : '1.2-3'
        }}{{ emma.BASE_UNIT_SYMBOLS[asEmma(notification).parentRule.thresholdUnit.baseUnit.unit] }}
        {{ asEmma(notification).parentRule?.alertType }} limit)</span
        >
        <span> on {{ notification.received | date : 'MM/dd/yyyy' }}</span>
      </div>
      <div
        style="font-size: 12px; text-decoration: underline; cursor: pointer"
        [routerLink]="['/services/energy-management/alerts']"
        (click)="notificationService.showNotifications$.next(false)"
        >
        See alert log entry
      </div>
    </ng-container>-->

  <ng-container>
    <div class="service-content">
      <flex-col>
        @if (notification.serviceNotification.responsible !== 'emma') {
          <div class="service-content__title">
            {{ notification.serviceNotification.description }}
            <span> on {{ notification.received | date: 'MM/dd/yyyy' }}</span>
          </div>
        }
        @if (notification.serviceNotification.responsible === 'emma') {
          <div class="service-content__title">
            <span class="bold">{{ asEmma(notification).queryableName }} </span>
            <span class="bold">{{ emma.periodLabel(asEmma(notification)) | lowercase }}</span> measured value
            <span class="bold">
              {{ asEmma(notification).value | number: '1.2-3' }}
              {{ emma.UNIT_PREFIXES[asEmma(notification).valueUnit.prefix].symbol }}
              {{ emma.BASE_UNIT_SYMBOLS[asEmma(notification).valueUnit.baseUnit.unit] }}
              (
              {{ asEmma(notification).value - asEmma(notification).threshold | absoluteNumber | number: '1.2-3' }}
              {{ emma.UNIT_PREFIXES[asEmma(notification).thresholdUnit.prefix].symbol }}
              {{ emma.BASE_UNIT_SYMBOLS[asEmma(notification).thresholdUnit.baseUnit.unit] }}
              {{ asEmma(notification).alertType }} limit )</span
            >
            <span> on {{ notification.received | date: 'MM/dd/yyyy' }}</span>
          </div>
        }
      </flex-col>
      <div
        class="service-content__link"
        [routerLink]="getRouterLink(notification.serviceNotification.responsible)"
        [fragment]="notification.serviceNotification.id"
        (click)="openNotification($event)">
        Show
      </div>
    </div>
  </ng-container>
</flex-row>
